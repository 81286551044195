import actions from '../actions';

export const initialState = {
  crop: null,
  file: null,
  fileWithOverlay: null,
  videoFile: null,
  videoUrl: '',
  isDescription: false,
  isReview: false,
  isAdbuck: false,
  buckTitle: '',
  duplicateTitle: false,
  originalTitle: '',
  denomination: 0,
  originalDenomination: 0,
  rarityClass: '',
  originalRarityClass: '',
  supply: null,
  originalSupply: null,
  supplyError: '',
  bucksCollection: '',
  description: '',
  marketPrice: null,
  collectionId: '',
  collectionList: [],
  imagePath: '',
  buckId: null,
  showLoader: false,
  isEdit: false,
  design: '',
  showDesign: true,
  showReuploadImage: false,
  invalidTitle: false,
  adbuckDescription: '',
  mediaType: null
};

const saveState = (state) => {
  try {
    if (state.files) state.files = [];
    const serializedState = JSON.stringify(state);
    localStorage.setItem('bucks', serializedState);
  } catch {
    // ignore write errors
  }
};
export function Bucks(state = initialState, action) {
  switch (action.type) {
    case actions.SET_BUCKS_STATE: {
      saveState({ ...state, ...action.payload });
      return { ...state, ...action.payload };
    }
    case actions.RESET_BUCKS_STATE: {
      saveState({ ...initialState });
      return { ...initialState };
    }
    default:
      return state;
  }
}
