import actions from '../actions';

export const initialState = {
  isEdit: false,
  originalFiles: null,
  showCropModal: false,
  files: [],
  openBucks: false,
  bundleTitle: '',
  description: '',
  quantity: '',
  supplyMinted: 0,
  bucksList: [],
  bucksIds: [],
  isBundlePrice: false,
  ipfsHash: '',
  selectedBucksIds: [],
  marketValue: 0,
  showBucksLoader: false,
  showLoader: false,
  duplicateTitle: false,
  originalTitle: '',
  isOpen: false,
  invalidTitle: false,
  rarityCount: []
};

const saveState = (state) => {
  try {
    if (state.files) state.files = [];
    if (state.bucksList) state.bucksList = [];
    const serializedState = JSON.stringify(state);
    localStorage.setItem('bundle', serializedState);
  } catch {
    // ignore write errors
  }
};
export function Bundle(state = initialState, action) {
  switch (action.type) {
    case actions.SET_BUNDLE_STATE: {
      saveState({ ...state, ...action.payload });
      return { ...state, ...action.payload };
    }
    case actions.RESET_BUNDLE_STATE: {
      localStorage.setItem('bundle-image', null);
      saveState({ ...initialState });
      return { ...initialState };
    }
    default:
      return state;
  }
}
