import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
// import { debounce } from '@material-ui/core';
import Select from 'react-select';
// import axios from 'axios';

import CountriesJSON from '../../Components/Common/Modals/countries.json';
import { APIUtiltity } from '../../API';
import { IMAGE_SIZE_LIMIT, errorMsg, successMsg } from '../../config';
import { MSG } from '../../msg-const';
import CropModal from '../Common/CropModal/CropModal';
import utils from '../../utils';

// import { ReactComponent as CheckIcon } from '../../img/svg/CheckMark/minicheck.svg';
// import { ReactComponent as EyeIcon } from '../../img/svg/Others/password-eye.svg';
import { ReactComponent as CopyIcon } from '../../img/svg/Actions/Copy1.svg';

function AccountCompletion({ userData = {}, setStep = () => {} }) {
  const [submitting, setSubmitting] = useState(false);
  const [section, setSection] = useState('location'); // location -> displayname -> privatekey
  const [showError, setShowError] = useState(false);
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const decryptedPrivateKey = userData?.privateKey
    ? utils.getDecryptedPrivateKey(userData.privateKey)
    : '';

  const accountCompleteionHandler = async () => {
    if (section === 'location') {
      if (!country || !zipCode.trim() || !isAccepted) return setShowError(true);
      if (submitting) return;
      setShowError(false);
      setSubmitting(true);
      const data = { country, zipCode };
      const res = await APIUtiltity.updateDisplayName(data);
      if (res.status === 200) setSection('displayname');
      else errorMsg(res.message);
      setSubmitting(false);
    } else if (section === 'displayname') {
      if (!firstName.trim() || !lastName.trim()) return setShowError(true);
      if (submitting) return;
      setShowError(false);
      setSubmitting(true);
      if (profilePictureFile) {
        const formdata = new FormData();
        formdata.append('image', profilePictureFile);
        await APIUtiltity.updateProfilePic(userData.walletId, formdata);
      }
      const data = { firstName, lastName };
      const res = await APIUtiltity.updateDisplayName(data);
      if (res.status === 200) setSection('privatekey');
      else errorMsg(res.message);
      setSubmitting(false);
    } else {
      navigator.clipboard.writeText(decryptedPrivateKey);
      successMsg(MSG['COPY_TO_CLIPBOARD']);
      setStep(3);
    }
  };

  return (
    <div className="account-setup-modal">
      {section === 'location' ? (
        <div className="modal-title mb-4">
          Almost there, to enhance your experience please let us know{' '}
          <span className="pink">your general location</span>
        </div>
      ) : section === 'displayname' ? (
        <div className="modal-title mb-4">
          Next, quickly create your{' '}
          <span className="pink"> StarStake Profile</span>
        </div>
      ) : null}
      <Form className="modal-form mb-4" autoComplete="off">
        {section === 'location' ? (
          <Location
            country={country}
            setCountry={setCountry}
            zipCode={zipCode}
            setZipCode={setZipCode}
            isAccepted={isAccepted}
            setIsAccepted={setIsAccepted}
            showError={showError}
            setShowError={setShowError}
            submitting={submitting}
          />
        ) : section === 'displayname' ? (
          <DisplayName
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            profilePictureFile={profilePictureFile}
            setProfilePictureFile={setProfilePictureFile}
            showError={showError}
            setShowError={setShowError}
            submitting={submitting}
          />
        ) : (
          <PrivateKey privateKey={decryptedPrivateKey} />
        )}
        <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
          <Button
            disabled={submitting || showError}
            className="modal-submit-button"
            onClick={accountCompleteionHandler}
          >
            {section === 'privatekey' ? 'Copy & Continue' : 'Continue'}
          </Button>
          {section !== 'privatekey' && (
            <p
              className="later-text-btn text-white"
              onClick={() =>
                section === 'location'
                  ? setSection('displayname')
                  : setSection('privatekey')
              }
            >
              I’ll do this later
            </p>
          )}
        </div>
      </Form>
    </div>
  );
}

export default AccountCompletion;

const unwantedCountries = [
  'DZ',
  'BD',
  'BO',
  'KH',
  'CN',
  'CO',
  'EC',
  'ID',
  'JO',
  'KG',
  'MN',
  'MA',
  'NP',
  'PK',
  'SA',
  'KR',
  'TW',
  'UZ',
  'VN'
];
const countries = Object.entries(CountriesJSON)
  .filter(([code = '']) => !unwantedCountries.includes(code))
  .map(([code, country]) => ({ label: country, value: code }));

const Location = ({
  submitting = false,
  country = '',
  setCountry = () => {},
  zipCode = '',
  setZipCode = () => {},
  isAccepted = false,
  setIsAccepted = () => {},
  showError = false,
  setShowError = () => {}
}) => {
  const [invalidZipCode, setInvalidZipCode] = useState(false);
  // const [cityStateText, setCityStateText] = useState('');
  // const searchByPinCode = (pincode = '') => {
  //   if (pincode.length < 3) return;
  //   console.log('called');
  //   axios.get('https://api.postalpincode.in/pincode/' + pincode).then((res) => {
  //     console.log(res);
  //   });
  // };
  // const debounceRef = React.useRef(debounce(searchByPinCode, 800));

  const updateZipCode = (newZipCode) => {
    if (!/^\d+$/.test(newZipCode)) {
      setShowError(true);
      setInvalidZipCode(true);
    } else {
      // debounceRef.current(newZipCode);
      setInvalidZipCode(false);
      setShowError(false);
    }
    setZipCode(newZipCode);
  };

  return (
    <div className="location-upload-modal-content mb-4">
      <h3 className="form-title">your location</h3>
      <p className="form-desc">
        Your location helps us curate the best experience for you to get the
        most from StarStake. We never share your personal location!
      </p>
      <div className="location-inputs-wrapper">
        <div className="w-100">
          <Select
            isSearchable
            closeMenuOnSelect
            className="react-select-container country-select-input"
            classNamePrefix="react-select"
            placeholder="Select Country"
            options={countries.sort((a, b) => {
              if (a.label < b.label) return -1;
              else if (a.label > b.label) return 1;
              return 0;
            })}
            onChange={(e) => {
              if (submitting) return;
              setCountry(e.label);
              if (showError) setShowError(false);
            }}
            value={countries.filter(({ label }) => label === country)}
          />
          {showError && (
            <Form.Text className="text-pink">
              {!country
                ? 'Please select country'
                : !zipCode
                ? 'Please enter zip code'
                : invalidZipCode
                ? 'Please enter valid zip code'
                : ''}
            </Form.Text>
          )}
        </div>
        <div className="w-100">
          <Form.Control
            type="text"
            placeholder="Enter Zip Code"
            onChange={(e) => {
              if (submitting) return;
              if (!country) return errorMsg('Please select country first');
              updateZipCode(e.target.value);
            }}
            value={zipCode}
          />
          {/* <div className="city-state-text">
            {cityStateText !== '' && (
              <span className="tick-icon">
                <CheckIcon />
              </span>
            )}
            <span>{cityStateText}&nbsp;</span>
          </div> */}
        </div>
      </div>
      <div className="checkbox-text-wrapper">
        <Form.Check
          type="checkbox"
          checked={isAccepted}
          onChange={() => {
            if (submitting) return;
            if (isAccepted) setShowError(true);
            else setShowError(false);
            setIsAccepted(!isAccepted);
          }}
        />
        <p className="mb-0">
          I hereby confirm that the above information is correct and understand
          I can change this at anytime under profile settings.
        </p>
      </div>
      {showError && country && zipCode && !invalidZipCode && (
        <Form.Text className="text-pink">
          Please tick confirmation box
        </Form.Text>
      )}
    </div>
  );
};

const DisplayName = ({
  submitting = false,
  firstName = '',
  setFirstName = () => {},
  lastName = '',
  setLastName = () => {},
  profilePictureFile = null,
  setProfilePictureFile = () => {},
  showError = false,
  setShowError = () => {}
}) => {
  const [showCropModal, setShowCropModal] = useState(false);
  const fileInput = React.useRef(null);

  const checkValidation = async (firstName = '', lastName = '') => {
    if (submitting) return;
    setFirstName(firstName);
    setLastName(lastName);
    if (
      !/^[a-zA-Z ]{1,30}$/.test(firstName) ||
      (lastName.trim() && !/^[a-zA-Z ]{1,30}$/.test(lastName))
    )
      return setShowError(true);
    setShowError(false);
  };

  const fileSelectHandler = (event = {}) => {
    const [file] = event.target.files;
    if (file.size > IMAGE_SIZE_LIMIT)
      return errorMsg('Please upload image upto 20 mb size');
    const reader = new FileReader();
    reader.onloadend = async () => {
      setShowCropModal(true);
      setProfilePictureFile(
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="display-name-upload-modal-content mb-4">
      <div className="profile-picture-main-content">
        {!profilePictureFile || showCropModal ? (
          <>
            <div className="img-profile">
              <div className="default-profile-picture-display" />
            </div>
            <Form.Control
              ref={fileInput}
              type="file"
              placeholder="Change"
              onChange={fileSelectHandler}
              className="d-none"
              multiple={false}
              accept=".jpg, .jpeg, .png"
            />
            <Button
              onClick={() => fileInput?.current && fileInput.current.click()}
              disabled={submitting}
            >
              + Upload Profile Picture
            </Button>
          </>
        ) : (
          <>
            <div className="user-profile-picture-display">
              <img alt="" src={profilePictureFile?.preview} />
            </div>
            {/* <Form.Control
              ref={fileInput}
              type="file"
              placeholder="Change"
              onChange={fileSelectHandler}
              className="d-none"
              multiple={false}
              accept=".jpg, .jpeg, .png"
            />
            <Button
              onClick={() => fileInput?.current && fileInput.current.click()}
              disabled={submitting}
            >
              Change
            </Button> */}
            <Button
              className="remove-button"
              onClick={() => setProfilePictureFile(null)}
              disabled={submitting}
            >
              Remove
            </Button>
          </>
        )}
        <CropModal
          type="profilePicture"
          showCropModal={showCropModal}
          closeModal={() => {
            setProfilePictureFile(null);
            setShowCropModal(false);
          }}
          handleSave={(file) => {
            setProfilePictureFile(
              Object.assign(file, { preview: URL.createObjectURL(file) })
            );
            setShowCropModal(false);
          }}
          src={profilePictureFile}
        />
      </div>
      <h3 className="form-title">Display Name</h3>
      <p className="form-desc">
        Your display name is used to identify you to other fans and stars. You
        may change this at any time.
      </p>
      <div className="display-name-inputs-wrapper">
        <Form.Control
          type="text"
          placeholder="Enter first name"
          onChange={(e) => checkValidation(e.target.value, lastName)}
          value={firstName}
        />
        <Form.Control
          type="text"
          placeholder="Enter last name"
          onChange={(e) => checkValidation(firstName, e.target.value)}
          value={lastName}
        />
      </div>
      <Form.Text className="text-pink">
        {showError &&
          (!firstName.trim()
            ? `Please enter first name`
            : !lastName.trim()
            ? 'Please enter last name'
            : '*Only alphabets are allowed')}
        &nbsp;
      </Form.Text>
    </div>
  );
};

const PrivateKey = ({ privateKey = '' }) => {
  // const [viewPrivateKey, setViewPrivateKey] = useState(false);

  return (
    <div className="private-key-modal mb-4">
      <h4>
        <span>You’re All Set!</span> Make sure to document your private key and
        keep it stored in a safe place.
      </h4>
      <p>
        Before you continue, document your <span>PRIVATE KEY</span> by writing
        it down now. <span>DO NOT SHARE OR LOSE</span> your private key. Your
        Private Key is like a key to your home, It’s used for password recovery
        & connecting your wallet with other services. StarStake does NOT have
        access to your key and it cannot be recovered.
      </p>
      <h6>my private key</h6>
      <div className="private-key-display">
        <span className="w-100">{utils.getLargeAccountId(privateKey)}</span>
        {/* <div
          className="eye-icon ms-auto"
          onMouseDown={() => setViewPrivateKey(true)}
          onMouseUp={() => setViewPrivateKey(false)}
          onMouseLeave={() => setViewPrivateKey(false)}
          title="View Private Key"
        >
          <EyeIcon />
        </div> */}
        <div
          className="copy-icon"
          onClick={() => {
            navigator.clipboard.writeText(privateKey);
            successMsg(MSG['COPY_TO_CLIPBOARD']);
          }}
        >
          <CopyIcon />
        </div>
      </div>
    </div>
  );
};
