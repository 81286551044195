import actions from '../actions';

const initialState = {
  step: 1,
  wallet: '', //step1
  firstName: '', //step2
  lastName: '', //step2
  email: '', //step2
  phoneNumber: '', //step3
  experienceOfWeb3: [], //step5
  nftProject: null, //step6
  nftProjectLink: '', //step6
  aboutYourProducts: '', //step7
  aboutYourProductsLink: '', //step8
  instagramLink: '', // step9
  facebookLink: '', //step9
  twitterLink: '', //step9
  linkdinLink: '', //step9
  tiktokLink: '', //step9
  communitySize: '', //step10
  approxRevenue: '', //step11
  starStakeFeatures: [], //step12
  acheiveWithStarStake: '', //step13
  ethicalUse: null //step14
};

export default function CreatorHubInvitation(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_INVITATION_FORM: {
      return { ...state, ...action.payload };
    }
    case actions.RESET_INVITATION_FORM: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
