import { useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { APIUtiltity as api } from '../../API';
import MainContext from '../../contexts/MainContext';

import { ReactComponent as StakeHubLogo } from '../../img/svg/Logos/StakeHubLogo.svg';
import { ReactComponent as CreatorHubLogo } from '../../img/svg/Logos/CreatorHubLogo.svg';
import { ReactComponent as Settings } from '../../img/svg/Assets/Settingsblue.svg';
import { ReactComponent as Certificate } from '../../img/svg/Assets/Certificate2.svg';
import { ReactComponent as Skip } from '../../img/svg/Actions/Skip.svg';
import { ReactComponent as Loader } from '../../img/svg/Common/Loader.svg';

const actionsArray = [
  {
    icon: <StakeHubLogo />,
    text: 'View my NFTs (StakeHub)',
    link: '/StakeHub'
  },
  {
    icon: <Certificate />,
    text: 'Enter Certificate Access Code',
    link: '/ClaimCertificateAccessNFT'
  },
  {
    icon: <CreatorHubLogo />,
    openText: 'Become a Creator (CreatorHub)',
    protectedText: 'Visit Creation Zone (CreatorHub)',
    openLink: '/ForCreators',
    protectedLink: '/CreatorHub'
  },
  {
    icon: <Skip style={{ transform: 'scale(0.8)' }} />,
    text: 'Skip & Continue on this page',
    link: null
  }
];

function QuickActions({
  userData = {},
  onHide = () => {},
  setClaimCertificateModal = () => {}
}) {
  const context = useContext(MainContext);
  const history = useHistory();
  const [hasCreatorAccess, setHasCreatorAccess] = useState(
    userData.userType === 'agent' || userData.userType === 'star'
  );
  const [loading, setLoading] = useState(false);

  const checkUserCreator = async () => {
    setLoading(true);
    try {
      const data = await api.hasCreatorPageAccess(userData.walletId);
      if (data.status == 200) {
        setHasCreatorAccess(data.data.creatorHubAccess);
        setLoading(false);
      } else if (data.status === 401) context.updateSignature();
      else setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      userData.walletId &&
      (userData.userType === 'agent' || userData.userType === 'star')
    )
      checkUserCreator();
  }, [userData.walletId]);

  return (
    <div className="quick-actions-modal">
      <h4 className="mt-4">
        Welcome <span>{userData.nickName},</span>
      </h4>
      <p className="my-4 teleport-desc-text">
        Choose from these quick links, or{' '}
        <span
          className="text-white text-decoration-underline cursor-pointer"
          onClick={() => onHide(true)}
        >
          skip this step
        </span>{' '}
        to continue on this page.
      </p>
      {loading ? (
        <div className="my-5">
          <Loader />
        </div>
      ) : (
        <div className="d-flex flex-column gap-3 justify-content-center align-items-center mb-4">
          {actionsArray.map(({ icon, link, text, ...action }) => (
            <Button
              key={text || 'action'}
              variant="outline-primary"
              onClick={() => {
                onHide(true);
                if (link) {
                  link === '/ClaimCertificateAccessNFT'
                    ? setClaimCertificateModal(true)
                    : history.push(link);
                } else {
                  history.push(
                    hasCreatorAccess ? action.protectedLink : action.openLink
                  );
                }
              }}
            >
              <span className="me-2">{icon}</span>
              <p>
                {text
                  ? text
                  : hasCreatorAccess
                  ? action.protectedText
                  : action.openText}
              </p>
            </Button>
          ))}
        </div>
      )}
      <p className="text-center text-blue">
        <span
          className="text-hover-underline cursor-pointer"
          onClick={() => {
            onHide(true);
            history.push('/StakeHub/Setting');
          }}
        >
          <Settings className="me-2" />
          Visit Wallet Settings
        </span>
      </p>
    </div>
  );
}

export default QuickActions;
