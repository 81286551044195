import { ConnectExtension } from '@magic-ext/connect';
import { io } from 'socket.io-client';
import toast from 'react-hot-toast';

import { MSG } from './msg-const';

import { ReactComponent as InfoIcon } from './img/svg/Actions/AlertWhite.svg';
import { ReactComponent as Common } from './img/svg/Rarity/CommonWhite.svg';
import { ReactComponent as Collectable } from './img/svg/Rarity/CollectableWhite.svg';
import { ReactComponent as Rare } from './img/svg/Rarity/RareWhite.svg';
import { ReactComponent as UltraRare } from './img/svg/Rarity/UltraRareWhite.svg';
import { ReactComponent as Legendary } from './img/svg/Rarity/LegendaryWhite.svg';
import { ReactComponent as Digital } from './img/svg/Crypto/Digital.svg';
import { ReactComponent as DigitalSeal } from './img/svg/AccessNFTSeal/Digital.svg';
import { ReactComponent as Product } from './img/svg/Assets/Product.svg';
import { ReactComponent as ProductSeal } from './img/svg/AccessNFTSeal/Product.svg';
import { ReactComponent as Event } from './img/svg/Assets/Calender.svg';
import { ReactComponent as EventSeal } from './img/svg/AccessNFTSeal/Event.svg';
import { ReactComponent as Certificate } from './img/svg/Assets/Certificate.svg';
import { ReactComponent as CertificateSeal } from './img/svg/AccessNFTSeal/Certificate.svg';
import { ReactComponent as Twitter } from './img/svg/SocialMedia/Twitter.svg';
import { ReactComponent as Facebooks } from './img/svg/SocialMedia/FaceBook.svg';
import { ReactComponent as Instagrams } from './img/svg/SocialMedia/InstaGram.svg';
import { ReactComponent as Youtube } from './img/svg/SocialMedia/YouTube.svg';
import { ReactComponent as LinkedinIcon } from './img/svg/SocialMedia/LinkedIn1.svg';

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_KEY;
export const USERSNAP_API_KEY = process.env.REACT_APP_USERSNAP_KEY;
export const CHAINID = [1, 137, 80001]; //1,5,137,80001
export const POLYGONID = [137, 80001, 5];
export const MAINNETID = [1, 5]; //[1, 5];
//it will be 100 because 25% is exclusive
export const TOTAL_ROYALTY_SHARES = 100;
export const TOTAL_ROYALTY_REFERAL_SHARES = 7;
export const DESCRIPTION_LENGTH = 1500;
export const MAX_TEXT_EDITOR_LENGTH = 5000;
export const TITLE_LENGTH = 45;
export const INTRO_LENGTH = 280;
export const IMAGE_SIZE_COMPRESS_LIMIT = 2097152; // 2 Mb
export const IMAGE_SIZE_LIMIT = 20971520; // 20 Mb
export const URL_ENCODING_KEY = 'star-stake@2021-2099';
export const CROSS_URL_ENC_KEY = '_cross@1#S!te-$t^rSt@ke';
export const PASSWORD_ENCODING_KEY =
  process.env.REACT_APP_PASSWORD_ENCODING_KEY || '';
export const SECRET_ENCODING_KEY =
  process.env.REACT_APP_SECRET_ENCODING_KEY || '';
export const RECEIVER = '0x2de076971eb69ae73afbd3eff046372ca09db1c7';
export const LIST_ADDRESS_BUCKS = '0x5666d305763caad1906daf5db161d32120dd6E7b';
export const LIST_ADDRESS_TREASURY =
  '0x0Eb0f6a1Ae8dD703579D8ED7d6252637d7424593';
export const LIST_ADDRESS_USDC = '0xf5450B51bdfA0fBa4760C77B9B7B26a7034eF93b';
export const STARSTAKE_STARTER_BUNDLE_ID = '6218505e18b3fbf4c8142951';
export const MNTR_CONTRACT_ADDRESS =
  '0x21ffa8ea8aedc4d5fb8a4fe83efc40058b3a75c8';
export const MINT_CONTRACT_ADDRESS =
  '0xD881120d062F24EBF7D2A87e574F3996E6533c6b';
export const BUY_CRYPTO_ADDRESS = '0x1D9cD602eA1d464Ed26Fb2bA377fa8c51786bcB8';
export const CLAIM_CONTRACT_ADDRESS =
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const ACCESS_NFT_CONTRACT_ADDRESS =
  '0xcEAe4D43502077E6CeaCE1C39a361B24E3E2e2FF';
export const BUY_TRANSFER_MATIC_TO_WALLET =
  'https://dfi.space/buy-matic?address=';
export const MINT_API_URL = process.env.REACT_APP_MISSION_NFT_ENDPOINT;
export const END_POINT_URL = process.env.REACT_APP_ENDPOINT;
export const ANFT_CERTIFICATE_IFRAME_ENDPOINT =
  process.env.REACT_APP_ANFT_CERTIFICATE_IFRAME_ENDPOINT;
export const SHORT_CODE_URL = process.env.REACT_APP_SHORT_CODE_URL;
export const VIDEO_STREAM_URL = process.env.REACT_APP_IMAGES_PUBLIC_URL;
export const VOOMLY_BASE_URL =
  'https://embed.voomly.com/embed/assets/embed.html?type=v&videoId=';
export const IMG_IPFS_HOST = END_POINT_URL + '/private-images/';
export const MOON_PAY_URL = process.env.REACT_APP_MOON_PAY_URL;
export const REACT_APP_IMAGES_PUBLIC_URL = END_POINT_URL + '/images';
export const MISSION_NFT_CLOUDFRONT_UTL =
  'https://dlxgn5205bony.cloudfront.net/mission-nft/';
export const NFTRewardLink = 'https://polygonscan.com/tx/';
export const FORTMATIC_PRIVATE_KEY =
  process.env.REACT_APP_FORTMATIC_PRIVATE_KEY;
export const POLY_FORTMATIC_NODE = {
  rpcUrl:
    'https://polygon-mainnet.infura.io/v3/38e29bc786a14506a129fa39d643decd', // your own node url
  chainId: 137 // chainId of your own node
};
export const ETH_FORTMATIC_NODE = {
  rpcUrl: 'https://mainnet.infura.io/v3/ae08ac918c954a7f8f4bb21eafdeb92d', // your own node url
  chainId: 1 // chainId of your own node
};

export const MAINNET_HTTP_ETH =
  'https://mainnet.infura.io/v3/99f87cfb6a2b4aaf9c2445644dcdbe35';

export const MAGICLINK_PRIVATE_KEY =
  process.env.REACT_APP_MAGICLINK_PRIVATE_KEY;
export const ETH_MAGICLINK_NODE = {
  network: ETH_FORTMATIC_NODE,
  extensions: [new ConnectExtension()]
};
export const POLY_MAGICLINK_NODE = {
  network: POLY_FORTMATIC_NODE,
  extensions: [new ConnectExtension()]
};
export const EDITOR_KEY = process.env.REACT_APP_EDITOR_KEY;
export const NOTIFICATION_SOCKET_URL =
  process.env.REACT_APP_NOTIFICATION_SOCKET_URL || '';
export const socket = io(NOTIFICATION_SOCKET_URL, { autoConnect: false });
export const isMobileDevice =
  'ontouchstart' in window || 'onmsgesturechange' in window;
export const GET_STARTED_TRAINING_CHAPTER_ID =
  process.env.REACT_APP_GET_STARTED_TRAINING_COURSE_ID || '';
export const CREATOR_STARTED_TRAINING_CHAPTER_ID =
  process.env.REACT_APP_CREATOR_STARTED_TRAINING_COURSE_ID || '';
export const STARSTAKE_BRANDCLUB_URL =
  process.env.REACT_APP_STARSTAKE_BRANDCLUB_URL || '';
export const STRIPE_KEY =
  'pk_live_51O0fDjBGiU7RnvShjnLrReOMenWqV336vKf1ir2NDPvAMa3FKf4crZD4eLac2OwVKYC6JKbw14DHyogDZt4hJd2o008v1ReFco';
//message alert
export const infoIcon = (
  <InfoIcon style={{ width: '20px', height: '20px', marginRight: '5px' }} />
);
export const successMsg = (msg = '') => toast.success(msg, { duration: 3000 });
export const errorMsg = (msg = MSG.SOMETHING_WRONG) => toast.error(msg);
export const infoMsg = (msg = '') =>
  toast(msg, { style: { backgroundColor: '#2F96B4' }, icon: infoIcon });
export const warningMsg = (msg = '') =>
  toast(msg, { style: { backgroundColor: '#F89406' }, icon: infoIcon });
export const invalidTitleErrorMsg =
  "*Only the punctuations mentioned in this bracket are allowed (| , - . ! ' _ & $)";

export const NOTIFICATION_MODULES = {
  REGISTRATION: '/StakeHub/TrainingCenter',
  BUCKS_PURCHASE: '/StakeHub/MyCollection',
  CREATORHUB_ACCESS: '/CreatorHub',
  CONVERT_ADBUCK: '/StakeHub/MyCollection',
  STAR_CONNECT: '/StakeHub/MyStarConnect',
  SNFT_PURCHASE: '/StakeHub/MyBrandClubs',
  SNFT_WATCHLIST: '/StakeHub/MyBrandClubs/Watchlist',
  REWARD: '/StakeHub/Rewards',
  PROMOTION: '/StakeHub/MyBrandClubs/Promotions',
  SNFT_PROMOTION: '/StakeHub/MyBrandClubs',
  ACCESS_NFT: '/StakeHub/AccessNFT/MyAccessNFTs',
  ACCESS_NFT_PURCHASE: '/StakeHub/AccessNFT/MyAccessNFTs',
  MANAGER: '/CreatorHub/MyManagers'
};

export const TRAINING_CENTER_CATEOGRIES = [
  { label: 'All', value: 'all' },
  { label: 'StarStake', value: 'starstake' },
  { label: 'StakeHub', value: 'stakehub' },
  { label: 'CreatorHub', value: 'creatorhub' },
  { label: 'Brand Club', value: 'snft' },
  { label: 'NFT Bucks', value: 'bucks' },
  { label: 'Access NFT', value: 'accessNft' }
];

export const NFTBUCK_OWNERSHIPSCORE = {
  common: 10,
  collectable: 20,
  rare: 100,
  ultrarare: 1000,
  legendary: 2000
};
export const RARITY_CLASS = [
  'common',
  'collectable',
  'rare',
  'ultrarare',
  'legendary'
];
export const RARITY_ICONS = {
  common: <Common style={{ width: 16 }} />,
  collectable: <Collectable style={{ width: 16 }} />,
  rare: <Rare style={{ width: 16 }} />,
  ultrarare: <UltraRare style={{ width: 16 }} className="ultrarare-icon" />,
  legendary: <Legendary style={{ width: 16 }} />
};

export const FAN_STATUS_WITH_ICONS = {
  connectedfan: (
    <img
      src={REACT_APP_IMAGES_PUBLIC_URL + '/FanBadges/ConnectedFan.webp'}
      alt="connected-fan"
    />
  ),
  activefan: (
    <img
      src={REACT_APP_IMAGES_PUBLIC_URL + '/FanBadges/ActiveFan.webp'}
      alt="active-fan"
    />
  ),
  truefan: (
    <img
      src={REACT_APP_IMAGES_PUBLIC_URL + '/FanBadges/TrueFan.webp'}
      alt="true-fan"
    />
  ),
  superfan: (
    <img
      src={REACT_APP_IMAGES_PUBLIC_URL + '/FanBadges/SuperFan.webp'}
      alt="super-fan"
    />
  )
};
export const CHECKLIST_MAPPING = {
  starConnect: 'StarConnect',
  brandClubLevel: 'Brand Club Level',
  nftBuck: 'NFT Buck',
  accessNft: 'Access NFT',
  customerScore: 'Customer Score',
  loyaltyScore: 'Loyalty Score',
  serviceScore: 'Service Score',
  ownershipScore: 'Ownership Score',
  totalFanScore: 'Total Fan Score'
};

export const RARITY_OPTIONS = [
  {
    value: 'common',
    label: 'Common',
    icon: <Common style={{ width: 16 }} />
  },
  {
    value: 'collectable',
    label: 'Collectable',
    icon: <Collectable style={{ width: 16 }} />
  },
  { value: 'rare', label: 'Rare', icon: <Rare style={{ width: 16 }} /> },
  {
    value: 'ultrarare',
    label: 'Ultra Rare',
    icon: <UltraRare style={{ width: 16 }} />
  },
  {
    value: 'legendary',
    label: 'Legendary',
    icon: <Legendary style={{ width: 16 }} />
  }
];
export const RARITY_OPTIONS_WITH_ICON = [
  {
    value: 'common',
    label: (
      <div className="icon-menusx">
        <Common className="me-2" />
        Common
      </div>
    )
  },
  {
    value: 'collectable',
    label: (
      <div className="icon-menusx">
        <Collectable className="me-2" />
        Collectable
      </div>
    )
  },
  {
    value: 'rare',
    label: (
      <div className="icon-menusx">
        <Rare className="me-2" /> Rare
      </div>
    )
  },
  {
    value: 'ultrarare',
    label: (
      <div className="icon-menusx">
        <UltraRare className="me-2" />
        Ultra Rare
      </div>
    )
  },
  {
    value: 'legendary',
    label: (
      <div className="icon-menusx">
        <Legendary className="me-2" />
        Legendary
      </div>
    )
  }
];

export const ACCESS_NFT_TYPE_WITH_ICONS = [
  {
    value: 'digitalaccess',
    label: (
      <div className="icon-menusx">
        <Digital className="me-2" />
        Digital Access
      </div>
    )
  },
  {
    value: 'eventaccess',
    label: (
      <div className="icon-menusx">
        <Event className="me-2" /> Event Access
      </div>
    )
  },
  {
    value: 'productaccess',
    label: (
      <div className="icon-menusx">
        <Product className="me-2" />
        Product Access
      </div>
    )
  }
];

export const ACCESS_NFT_ORDER_STATUS = [
  { value: 'purchased', label: 'Purchased' },
  { value: 'processing', label: 'Processing' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'problem-reported', label: 'Problem Reported' }
];

export const ACCESS_NFTS_TYPE_OPTIONS = {
  digitalaccess: <Digital />,
  eventaccess: <Event />,
  productaccess: <Product />,
  certificateaccess: <Certificate />
};

export const ACCESS_NFT_SEALS = {
  digitalaccess: <DigitalSeal />,
  eventaccess: <EventSeal />,
  productaccess: <ProductSeal />,
  certificateaccess: <CertificateSeal />
};

export const ACCESS_NFTS_TYPE_SEALS = [
  {
    value: 'digitalaccess',
    label: 'Digital',
    icon: <Digital style={{ width: 16 }} />,
    seal: 'Digital'
  },
  {
    value: 'eventaccess',
    label: 'Event',
    icon: <Event style={{ width: 16 }} />,
    seal: 'Event'
  },
  {
    value: 'productaccess',
    label: 'Product',
    icon: <Product style={{ width: 16 }} />,
    seal: 'Product'
  },
  {
    value: 'certificateaccess',
    label: 'Certificate',
    icon: <Certificate style={{ width: 16 }} />,
    seal: 'Certificate'
  }
];

//SNFT section
export const SNFT_CATEGORY_OPTIONS = [
  { value: 'music', label: 'Music' },
  { value: 'sports', label: 'Sports' },
  { value: 'publishing', label: 'Publishing' },
  { value: 'product', label: 'Product' },
  { value: 'media & entertainment', label: 'Media & Entertainment' },
  { value: 'art', label: 'Art' },
  { value: 'gaming', label: 'Gaming' },
  { value: 'influencer', label: 'Influencer' },
  { value: 'coach', label: 'Coach' },
  { value: 'brand', label: 'Brand' },
  { value: 'personal', label: 'Personal' },
  { value: 'professional', label: 'Professional' },
  { value: 'non-profit', label: 'Non-profit' },
  { value: 'experts', label: 'Experts' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'health/wellness', label: 'Health/wellness' },
  { value: 'retail', label: 'Retail' },
  { value: 'education', label: 'Education' },
  { value: 'science & technology', label: 'Science & Technology' },
  { value: 'culinary', label: 'Culinary' },
  { value: 'social media', label: 'Social Media' },
  { value: 'content creator', label: 'Content Creator' },
  { value: 'toys', label: 'Toys' },
  { value: 'training', label: 'Training' },
  { value: 'tools', label: 'Tools' },
  { value: 'pets', label: 'Pets' },
  { value: 'health care', label: 'Health care' },
  { value: 'agriculture', label: 'Agriculture' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'real estate', label: 'Real estate' },
  { value: 'finance', label: 'Finance' },
  { value: 'aerospace', label: 'Aerospace' },
  { value: 'public figure', label: 'Public figure' },
  { value: 'horticulture', label: 'Horticulture' },
  { value: 'service', label: 'Service' },
  { value: 'e-commerce', label: 'E-commerce' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'podcast', label: 'Podcast' },
  { value: 'home & garden', label: 'Home & Garden' },
  { value: 'decorator', label: 'Decorator' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'films', label: 'Films' },
  { value: 'extreme sports', label: 'Extreme Sports' },
  { value: 'games', label: 'Games' },
  { value: 'athlete', label: 'Athlete' },
  { value: 'cause', label: 'Cause' },
  // { value: 'service', label: 'Service' },
  { value: 'gym & exercise', label: 'Gym & Exercise' },
  { value: 'nutrition', label: 'Nutrition' },
  { value: 'photography', label: 'Photography' },
  { value: 'recreation', label: 'Recreation' },
  { value: 'software', label: 'Software' },
  { value: 'business', label: 'Business' },
  { value: 'entrepreneur', label: 'Entrepreneur' },
  { value: 'specialist', label: 'Specialist' },
  { value: 'farming', label: 'Farming' },
  { value: 'living', label: 'Living' },
  { value: 'lifestyle', label: 'Lifestyle' },
  { value: 'cultural', label: 'Cultural' }
];
export const SNFT_PRICING_OPTIONS = [
  { label: 'Set Price', value: 'set' },
  { label: 'Open Price', value: 'open' },
  { label: 'Minimum Price', value: 'minimum' }
];
export const SNFT_AVAILABILTITY_OPTIONS = [
  { label: 'Ends Today (24h)', value: 'today' },
  { label: 'Ending Soon (30d)', value: 'soon' },
  { label: 'Available', value: 'available' }
];

export const SALES_TAX_VALUES = { sNFT: 5, accessNFT: 3 };

export const SOCIAL_LINK_ICONS = {
  facebook: <Facebooks className="social-icon" />,
  instagram: <Instagrams className="social-icon" />,
  linkedin: <LinkedinIcon className="social-icon" />,
  twitter: <Twitter className="social-icon" />,
  youtube: <Youtube className="social-icon" />
};

// socialMediaOptions
export const SOCIAL_MEDIA_OPTIONS = [
  {
    value: 'twitter',
    label: (
      <div className="icon-menu-social">
        <Twitter /> Twitter
      </div>
    )
  },
  {
    value: 'facebook',
    label: (
      <div className="icon-menu-social">
        <Facebooks /> Facebook
      </div>
    )
  },
  {
    value: 'instagram',
    label: (
      <div className="icon-menu-social">
        <Instagrams /> Instagram
      </div>
    )
  },
  {
    value: 'youtube',
    label: (
      <div className="icon-menu-social">
        <Youtube /> Youtube
      </div>
    )
  }
];

// design options
export const NFTB_DESIGN_OPTIONS = [
  { value: 'none', label: 'None (Custom Design)' },
  { value: 'Style #1 Light', label: 'Style #1 Light' },
  { value: 'Style #1 Dark', label: 'Style #1 Dark' },
  { value: 'Style #2 Light', label: 'Style #2 Light' },
  { value: 'Style #2 Dark', label: 'Style #2 Dark' },
  { value: 'Style #3', label: 'Style #3' },
  { value: 'Style #4', label: 'Style #4' },
  { value: 'Style #5', label: 'Style #5' }
];

export const STARCONNECT_BACKGROUND_STYLE = [
  { label: 'Default Background', value: 'red' },
  { label: 'Bronze Background', value: 'bronze' },
  { label: 'Jade Background', value: 'jade' },
  { label: 'Blue Background', value: 'blue' },
  { label: 'Maroon Background', value: 'maroon' },
  { label: 'Gold Background', value: 'gold' }
];

export const COLLECTION_FILTER_OPTION_WITH_LABEL = [
  { value: 'All', label: 'All' },
  { value: 'Standard', label: 'Standard' },
  { value: 'Limited', label: 'Limited' },
  { value: 'StarStake', label: 'StarStake' }
];
// collection filter

export const COLLECTION_FILTER_OPTION = [
  'All',
  'Standard',
  'Limited',
  'StarStake'
];
// denomonation filter

export const DENOMINATION_FILTER_OPTION = [
  'All',
  '1',
  '5',
  '10',
  '20',
  '50',
  '100',
  '500',
  '1000',
  '10,000',
  '100,000',
  '1,000,000'
];
export const DENOMINATION_FILTER_OPTION_WITH_SYMBOL = [
  { value: 'All', label: 'All' },
  { value: 1, label: '$ 1' },
  { value: 5, label: '$ 5' },
  { value: 10, label: '$ 10' },
  { value: 20, label: '$ 20' },
  { value: 50, label: '$ 50' },
  { value: 100, label: '$ 100' },
  { value: 500, label: '$ 500' },
  { value: 1000, label: '$ 1000' },
  { value: 10000, label: '$ 10,000' },
  { value: 100000, label: '$ 100,000' },
  { value: 1000000, label: '$ 1,000,000' }
];

export const FAN_STATUS = [
  { value: 'connected', label: 'ConnectedFan' },
  { value: 'active', label: 'ActiveFan' },
  { value: 'true', label: 'TrueFan' },
  { value: 'super', label: 'SuperFan' }
];

export const TUTORIAL_VIDEO_ID = {
  Default: 'maYlSw0ToBrdn1-VRUNzJcLMXZSTND00k2sveWcFDUcSnv99a',
  Landing_Banner: 'maYlSw0ToBrdn1-VRUNzJcLMXZSTND00k2sveWcFDUcSnv99a',
  ForCreators_Banner: 'Zt-Wkq5EOOA',
  // Purchase
  NFTBucks_Purchase_Success:
    'QAbMhefUUYOaOVod18j6UtTONnE4cHtO1Do9VgBJWNK411F0d',
  BrandClub_Purchase_Success:
    'QAbMhefUUYOaOVod18j6UtTONnE4cHtO1Do9VgBJWNK411F0d',
  AccessNFT_Purchase_Success:
    'QAbMhefUUYOaOVod18j6UtTONnE4cHtO1Do9VgBJWNK411F0d',
  // StakeHub
  StakeHub_Walkthrough: 'MUm_YfN3PFY',
  StakeHub_Tour: 'PIqgg15rE0bBh1pe2xQ-6d3VAu7WEMIBO98U6IEL8kk7J0lZA',
  // Quick Tour
  StakeHub_QuickTour_1: 'QAlLxJPMw8muAU-QEhAu3amXnykCBC-t0lXVQSMgfLLRaz9wB',
  // Brand Club Home Quick Tour
  BrandClubHome_QuickTour_1:
    'Y0dW2-ZMmBsOS1jZ1gHPFNS93DaBGTLZyAvgqYcCPxWdi1FiG',
  BrandClubHome_QuickTour_2:
    'XhhZiLceN0Z3M3IpU1o8159VKW9vWFDO3zhVG34ZJP6jNBFgA', // Your AccessNFTs
  BrandClubHome_QuickTour_3:
    'hFXYFA3eVMGNV25Dwt81vunUhBaA7TkSDpAFMH9zVNKJZlFAf', // Grow FanScore
  BrandClubHome_QuickTour_4:
    'Y0dW2-ZMmBsOS1jZ1gHPFNS93DaBGTLZyAvgqYcCPxWdi1FiG', // Build Fan Status
  BrandClubHome_QuickTour_5:
    'AgcFtR7SyK4olBIAzEHVri2RTGQaXmC5KqJFbwU6fItyDCEbF', // Win Rewards
  BrandClubHome_QuickTour_6:
    'iVxhN8kX2TGFBg8b1t6zGtfQCzSZTE2i3zIlEBoi_VarL3g1I', // Share Earn
  BrandClubHome_QuickTour_7:
    'zxdAUgLI0sKQDpxQFycsswymvEwUbwdDJJUlcDpyy2pN_pkhS', // ComingSoon
  // CreatorHub
  CreatorHub_Overview: 'gzeMdyRzl-E',
  StarStake_Managers: '8ceZb7VqY7k',
  About_Agents: 'inoJ0PwxYpw',
  CreatorHub_Creation_Success:
    'SQXXr1zS1I7f3F3OZcyGtkm3CgQ0H4NKmbQw3Q3VM0nJUE7Ef',
  // Home --> resources tab
  Tell_Me_About_StarStake: 'PWFDwpdPwRs',
  How_StarStake_Works: 'Zt-Wkq5EOOA',
  What_Is_StarStaker: 'vz90mB3AMX0',
  // Quick Tour
  CreatorHub_QuickTour_1: 'W4nFDMtSn9iNmk8LKdVqSoMEbQBZ2yyehpBtax8QRctvIAUi8',
  CreatorHub_QuickTour_2: 'yFHfgIjO3Zw6Y59sYFwYw4g7YXYOE1jC0G9hFFxcGMavO2V0e', // StarConnect
  CreatorHub_QuickTour_3: 'CxrMM1sO0DjV6jZgTswWHNdOJDh6zHrdnnd1QjgBAHo-bS4Su', // BrandClub
  CreatorHub_QuickTour_4: 'Quw9CELBAIFzbgwHYAhduvtqAXZNCFzzpztJJi9cOOdGSF12n', // NFTBucks
  CreatorHub_QuickTour_5: 'W4nFDMtSn9iNmk8LKdVqSoMEbQBZ2yyehpBtax8QRctvIAUi8', // AccessNFT
  CreatorHub_QuickTour_6: 'K0CtmA3C_BvZEB-ZBRaBei_BypgFAPvN18teDAUZLrDQykLOM', // Promotion
  CreatorHub_QuickTour_7: 'UkKH1NjHRGmRPFTsXYnUOBXfAxmDMxryxDYLAGVBLPqUI39wU', // Manage
  // NFT bucks
  NFT_Bucks_Dashboard: 'D8DCxgMmRs4ZmI0RAo0Q8VHJIgzEvxjz2aNAkAs2Uc82JTh9a',
  NFT_Bucks_Builder_Overview:
    'D8DCxgMmRs4ZmI0RAo0Q8VHJIgzEvxjz2aNAkAs2Uc82JTh9a', // need to be replaced
  NFT_Bucks_vs_Adbucks_Overview:
    'vBmoR8eNk8nHEcFcdKsrE8ksM2QwqDycc8J_VzgGRK1IeF44B',
  NFT_Bucks_Creation_Page1: 'yVsQw6Ia3yXFyht3lBaTD_DXGMAPHTc72KYgOWJVQiL53nxkL',
  NFT_Bucks_Description_and_submit:
    'xJHDDXMkv5-Jy0NsPlERHocLLHg5Ldg8ijKbAWYsU882JUR4C',
  Ad_Bucks_Creation: 'D_l_wdNEAgLXyUkOmR4trM_HSQKdQTin518JcmZ9MSbIPwXzb',
  NFT_Bucks_Bundle_Title_and_desc:
    'DzGAq003wFo_Ni12FGFdivtbjC9BaG8sagODV0UUB3TCez1Ad',
  Add_NFT_Bucks_to_Bundle: 'JMH1yjLA9JTxEYHRSySe187BRwGceE2rQMXWrctKeFVdk0Wz',
  NFT_Bucks_Bundle_Creation_Summary:
    'ErM6ZSx5kiYWcB19I2oU0jNMEWZjeszW6nY1AXddRNdrGrhha',
  // star profile
  Star_Profile_Tutorial: 'ZY1Zj6zNN453OjF4cgVBTuLLWWBuAHNGFsx9djGZQOoLOEUIf',
  Star_Profile_overview: 'H4DXn9CymApR3DQWiAFeszGKCkvRGsvjM4lEFoYFn6oc0F029',
  Star_Profile_Header_Section:
    'H4DXn9CymApR3DQWiAFeszGKCkvRGsvjM4lEFoYFn6oc0F029',
  Star_Profile_Contact_Me: 'lEnNlQAu1QLXLEfIVBEQ_s7JTK5EIBUKg3cpboLVTK5bWEROU',
  Star_Profile_Bio_section: 'rIATkpjRBNwBxhD-mh4oUT87IQdSeBS6pHSxbwEPBfrUZUJdV',
  // star connect
  StarConnect_Dashboard: '2jReVLcBz8qb3Q1O00-LsmE2Wh_TH9K2g8lBS7oQDON6Lr18d',
  StarConnect_Creation: 'zkgSGV0GjTfHi7eJCQKMNNJNsQjfH9M3nNSvQVgBgjbP2bM7Y',
  StarConnect_PageBuilder: 'WN4T09nGpjoV1VfD2UZqEvtuFBxYBGj06wN9IAtNH6bvHV4Ja',
  StarConnect_HeroSection: 'hMlMMYGbXMsHjQoxy4czv7fcDx3Xs00a3FZ2ETNGJPapR2F1M',
  StarConnect_OverviewSection:
    'Ic7qjx1SLmUhdyAqIlB8gT1qcw1gtCkSZG6XG9SEU3F-Lz0zJ',
  StarConnect_SuccessPage: '2NoPG1oPIQrdmxYVxK8jScvBTIgmVFHemx89Ull-mdLqejkiT',
  StarConnect_TokenContent: '6KQ7ibkUVZLDat9D31jTitkDXhMQACD_VwNtKsEIMMpDV0wVE',
  // sNFT
  sNFT_Dashboard_Overview: 'G4sjCMagR5eQwM0AFkEao5bLVxwqcDSb0ltpTASMgmb1C3xwI',
  sNFT_Builder_Overview: 'G6pLw1UaCOJUjlvXh0pm4xrbXcuHhzHe2hLVEMRfJNd0P3VrK',
  sNFT_General_Details: 'h1u30x_ZzFTL2Vg7SN0lisIaPCEkE3TS1y9LcE08KNK_PQVbQ',
  sNFT_General_Details_Headline:
    'KNopQb_2TSTbxVN8CnRcLoMLKTRmRDw3BzIxRiaJBJMBamx2c',
  sNFT_Availability_Options:
    'GYXV2UQ2Imw_C0NtNDwUwtdeNXkucGjG3MI63T5tVhUQ32qpM',
  sNFT_MarketHub_Thumbnail: 'naeQD1AZmb6NdBlYjEHEpZwlxlwTDs6Zi6ZNAsNHyCSMIEM9Z',
  sNFT_Details_Summary_screen:
    'HpbQQAh0DJz3ntG5fkMR2oMXCGA0eXyP20RdWQciRJEramLgF',
  sNFT_Access_Offers: 'V17Ur4FHxADYwEoBnUl5G82QkQOKf_CusnSsZVYWQQUaaJ7BQ',
  sNFT_Page_Builder: '1sLf1UznYkmf213T5FBlH9vCWLLQGzBO3FmPq9IANUv3MRQ1S',
  sNFT_Page_Section_Overview:
    'XJXBlZsgIH1oMx3eni0VLC8FLzN_GCh_zpCFF0glUGLrdzyLG',
  sNFT_Edit_Header_Section: '28xgiFdJ201S1lweCtMz55nfXRmDGzC3ydFFTr4FM6ue2312E',
  sNFT_Overview_Page_Section:
    'mJCFyAdCsyjWRw0qv0kScK0LIUVTOA6l6S5NdVZhNpyvQwUdW',
  sNFT_Review_Details: 'UAwYSE3VwkTTwEqRzVAnqsyTRPXyc-iqp1wOVAZxffbGHwBEE',
  sNFT_Token_Agreement: 'QUOsrk5pKK5aDr1dFmBsVUs0CrMqTAC6lggxewJwdhrDSAS_E',
  // promotion
  Promotion_Builder: 'QdTOyg94ShKRz6tcUkD5HJWEkQDQFAsn9KdIsDpZLE7KWg35X',
  Promotion_AccessReward: 'ntZ6K_iVjAILewwZAnB9BmsXKLARLB1mYrpZ7CpYSRbeClExV',
  Promotion_AccessRewardCriteria:
    'ntZ6K_iVjAILewwZAnB9BmsXKLARLB1mYrpZ7CpYSRbeClExV',
  Promotion_AccessNFTSelection:
    'XR4PZ0eDTtjJ0so3A9Ietg-K1R1_UGTKxyo8F4tYEN6nNFFvd',
  Promotion_SponsoredReward:
    'WCWMEElLlSXXnb7dGEXKyg7wncPGWfAMlG54SDqSKlSRQzQIK',
  Promotion_SponsoredRewardCriteria:
    'SlSULAtcSFyMz3MGxEVOpsfOnaLCCsoI2SFziI5BCFbqJyxyh',
  Promotion_DepositSponsoredAmount:
    'SlSULAtcSFyMz3MGxEVOpsfOnaLCCsoI2SFziI5BCFbqJyxyh', // need to be replaced
  Promotion_SharedSalesReward:
    'VM15RTUnP0dbWlgdPxhkrrKydVVuWBYHILpguF84f-7VQxwJA',
  Promotion_SharedSalesCriteria:
    'lgc-utLrVV_OhNYQXTwaMNqKgUDUF1nultlJG4MNaRTHjwCFn',
  // access NFT
  Access_NFT_Main_Dashboard:
    '96LERtvFv1mLGRcPkUxxjPAPS86QAiRzl5tRCdN_NwnSTR0oN',
  Access_NFT_Builder: 'RUJQk0uZgJ6RQcPIr88shgIUVVD80ymoQf5TUDpxcH7JLxCwB',
  Access_NFT_Type_Selection:
    'OIkF1BATRBmVE1LB0VhuutnaBbyJFD_VkbBKkFoJOCqTTjVGC',
  Access_NFT_Purchase_Creation:
    'AZmFDwPBegJAwM6a_B16kUBP1QSQlyj6XcvRaSQMdeBGzx9qm',
  Access_NFT_Purchase_Summary:
    'kRIFRcvZ3FmWgVkVvIPr1m9pURM5v9td39NMRz2ii1vGAhVND',
  Access_NFT_Purchase_Options:
    'EpCScK678VB7dxEeozCNV3cnAHAifBC8TgpNaCM0etC7Uxk9T',
  Access_NFT_Purchase_Delivery:
    'xxqhmAuJygqNX0mmnREnXsNJ1S5iQD5igRCRRA5TZdr9BjE7I',
  Access_NFT_Purchase_PageEditor:
    'hQSBvTlRyG8PldpDjI1tv9BHcUUOOx0fpCttPOlHDR6EMgdod',
  Access_NFT_Purchase_Review:
    'mwtXEvgHKMbQns4S0x59oszKFZcGHSz43pBZAsBPe7eEnUwVG',
  Access_NFT_Exclusive_Creation:
    'UBcuTZUDtS6DyxCXx1cuToMibRdwdy6nHUy5bgAVOQc6BxPsI',
  Access_NFT_Exclusive_Summary:
    '1M9USCwuMBNzp0j0Gixdbw9rIOhePEU-6uIJsR4gKB6HC0GxH',
  Access_NFT_Exclusive_Options:
    'nwxwABwgTRnKZmwBdTBnEpMHVngxSoSdayN6Uyh3LcrzUpMIS',
  Access_NFT_Exclusive_Delivery:
    'BhWQUoVCmhmGKxJWOm5BMr8_ASwworc0rn5deggCsJ7BWRcbT',
  Access_NFT_Exclusive_PageEditor:
    'SQIAEAvbwlPC1tRAi1luF69oGOxDfFzzsgdTrQhcPapKS3afb',
  Access_NFT_Exclusive_Review:
    'H9dholJNPhdfHbwOK7wzR7USCCtw4VgfpF9Q6yd8JEaEd01Yd',
  Access_NFT_Rewards_Creation:
    'Co8B3FOKwQ3BSoUfDwVl49Xc0UioDzOwZt-aTRi3m00aCGD-M',
  Access_NFT_Rewards_Summary:
    'Co8B3FOKwQ3BSoUfDwVl49Xc0UioDzOwZt-aTRi3m00aCGD-M',
  Access_NFT_Rewards_Options:
    'TlMIKBvOmwfSx9WV-sksrUTFUyBeRBU35gJJ-W8kuS7XSkhMA',
  Access_NFT_Rewards_Delivery:
    '319PlQGBO0PDBAcWEoI2v9_SUBXc9ETroxoWJR1BePqG72t_G',
  Access_NFT_Rewards_PageEditor:
    'FdCx215TXhPH0DORQsVQvFrDVaxPa2D6w3NNLQMMAaFbahaVD',
  Access_NFT_Rewards_Review:
    '2pvfD12VLXrDDUTxWgkyO4dcPNxrU1DHmw9lE1UwaParKjlxP',
  Access_NFT_Certificate_Creation:
    'E8Na4k4NbPD5G1Afd2EDvOwvWAMGDR1NOHIPuxd7boD8WBFfG',
  Access_NFT_Certificate_Summary:
    'HcXQntvUjl7jiUtYB0tmtYWFXMnZGDa5MxBDBo4EM560d1gjF',
  Access_NFT_Certificate_Options:
    'ARSRdo9NyiqKy0sBnkRZoMHJR7yTDKA-k1cZSJ5MRoyqb3Blb',
  Access_NFT_Certificate_Message:
    'ARSRdo9NyiqKy0sBnkRZoMHJR7yTDKA-k1cZSJ5MRoyqb3Blb', // need to be replaced
  Access_NFT_Certificate_Delivery:
    '10c6o17GJiFjPZLUa1geIi9_MixwYGDNdYQ1GCU7YYg2cVOdK',
  Access_NFT_Certificate_PageEditor:
    'ZEe1A2DIZjRWa2A4ZhgQPtNSfjr2BBokewB5SNtAFnall5V5O',
  Access_NFT_Certificate_Review:
    'pfaPxcLlbCHSLz0h3k0YM1aMKSRaaJprAHQ3cwVgWwr1Tmxxz',
  CUSTOM_FAN_STATUS: 'lwqmTNMjEB7SwQ4f3cgnTMIVFpVyU8yGcx81UBpVbI7rcwUFc'
};
