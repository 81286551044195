import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import './index.scss';
import EmailVerification from './EmailVerification';
import AccountSetup from './AccountSetup';
import AccountCompletion from './AccountCompletion';
import QuickActions from './QuickActions';
import ClaimCertificateAccessNFT from '../StakeHub/AccessNFT/Overview/ClaimCertificateAccessNFT';
import utils from '../../utils';

import { ReactComponent as CloseButton } from '../../img/svg/CrossMark/CircleCrossGrey.svg';

function ConnectModal({ show = false, onHide = () => {} }) {
  const [step, setStep] = useState(0);
  const [claimCertificateModal, setClaimCertificateModal] = useState(false);
  const [userData, setUserData] = useState({});

  React.useEffect(() => {
    const encryptedData = localStorage.getItem('session_data');
    if (encryptedData) {
      const { email = '', timestamp = '' } = utils.getDecrypted(encryptedData);
      const hours = timestamp ? moment().diff(timestamp, 'hours') : 25;
      if (hours < 24 && email) setStep(1);
      else setStep(0);
    }
  }, [show]);

  if (claimCertificateModal) {
    return (
      <ClaimCertificateAccessNFT
        onHide={() => {
          setClaimCertificateModal(false);
          onHide(false);
        }}
      />
    );
  }

  return (
    <Modal
      centered
      show={show}
      className="connect-modal"
      onHide={() => onHide(step > 1)}
      backdrop="static"
    >
      <div className="bg-blur-grad" />
      <Modal.Header className="modal-header">
        <h4>
          {step === 0
            ? 'Wallet Connect'
            : step === 3
            ? 'Wallet Connected!'
            : 'Getting Started'}
        </h4>
        <CloseButton
          className="modal-close-button"
          onClick={() => onHide(step > 1)}
        />
      </Modal.Header>
      <Modal.Body className="modal-body">
        {step === 0 ? (
          <EmailVerification
            setStep={setStep}
            onHide={onHide}
            setUserData={setUserData}
          />
        ) : step === 1 ? (
          <AccountSetup setStep={setStep} setUserData={setUserData} />
        ) : step === 2 ? (
          <AccountCompletion setStep={setStep} userData={userData} />
        ) : (
          <QuickActions
            userData={userData}
            onHide={onHide}
            setClaimCertificateModal={setClaimCertificateModal}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ConnectModal;
