import { MSG } from '../msg-const';

export default {
  async balanceOf(web3, ABI, contract, account) {
    try {
      let web3Object = new web3.eth.Contract(ABI, contract);
      let balance = await web3Object.methods.balanceOf(account).call();
      // console.log(allowance+">="+amount)
      return {
        result: true,
        msg: 'success',
        bal: balance
      };
    } catch (e) {
      // console.log("====",e)
      return {
        result: false,
        msg: e.message
      };
    }
  },

  async hasAllownce(web3, ABI, contract, account, spender, amount) {
    try {
      let web3Object = new web3.eth.Contract(ABI, contract);
      let allowance = await web3Object.methods
        .allowance(account, spender)
        .call();
      if (Number(allowance) >= Number(amount)) {
        return {
          result: true,
          msg: 'success'
        };
      } else {
        return {
          result: false,
          msg: 'success'
        };
      }
    } catch (e) {
      // console.log("====",e)
      return {
        result: false,
        msg: e.message
      };
    }
  },

  async approveLogic(web3, ABI, contract, account, spender, type) {
    try {
      let web3Object = new web3.eth.Contract(ABI, contract);
      let stakeAmount = web3.utils.toWei(
        '115792089237316195423570985008687907853269984665640564039457584007',
        'wei'
      );
      let gas = await web3.eth.getGasPrice();
      let gaslimit = await web3Object.methods
        .approve(spender, stakeAmount.toString())
        .estimateGas({
          from: account
        });
      const res = await web3Object.methods
        .approve(spender, stakeAmount.toString())
        .send({
          from: account,
          gasPrice: Number(gas) + 50000000000,
          gasLimit: web3.utils.toHex(gaslimit)
        });
      return {
        result: true,
        msg: 'success'
      };
    } catch (e) {
      return {
        result: false,
        msg: e.message.split('{')[0]
      };
    }
  },

  async sendTxWithoutValue(web3Object, web3, args, funcName, account) {
    try {
      let gasLimit = await web3Object.methods[funcName](...args).estimateGas({
        from: account
      });
      // console.log("=-======>", gaslimit)
      let gasPrice = await web3.eth.getGasPrice();
      try {
        await web3Object.methods[funcName](...args).send({
          from: account,
          gasPrice: Number(gasPrice) + 10000000000,
          gasLimit: web3.utils.toHex(gasLimit)
        });
        return {
          result: true,
          msg: 'success'
        };
      } catch (e) {
        let msg = MSG.SOMETHING_WRONG;
        if (e.code === 4001) {
          //user rejected the transaction
          msg = e.message;
        }
        return {
          result: false,
          msg: msg
        };
      }
    } catch (e) {
      return {
        result: false,
        msg: e.message.split('{')[0]
      };
    }
  },

  async sendTx(web3Object, web3, args, funcName, account, amount) {
    try {
      let gasLimit = await web3Object.methods[funcName](...args).estimateGas({
        from: account,
        value: amount
      });
      // console.log("=-======>", gaslimit)
      let gasPrice = await web3.eth.getGasPrice();
      try {
        await web3Object.methods[funcName](...args).send({
          from: account,
          gasPrice: Number(gasPrice) + 10000000000,
          gasLimit: web3.utils.toHex(gasLimit),
          value: amount
        });
        return {
          result: true,
          msg: 'success'
        };
      } catch (e) {
        console.log('error in transfer', e);
        let msg = 'Oops! Something went wrong. Please try again later';
        if (e.code === 4001) {
          //user rejected the transaction
          msg = e.message;
        }
        return {
          result: false,
          msg: msg
        };
      }
    } catch (e) {
      return {
        result: false,
        msg: e.message.split('{')[0]
      };
    }
  }
};
