import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import './index.scss';
import { ReactComponent as CloseButton } from '../../img/svg/CrossMark/CircleCrossGrey.svg';
import { ReactComponent as EyeIcon } from '../../img/svg/Others/password-eye.svg';

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;

function TransactionVerificationModal({ show = false, onHide = () => {} }) {
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [showPasswordErrorMsg, setShowPasswordErrorMsg] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  React.useEffect(() => {
    if (show) {
      setSubmitting(false);
      setPassword('');
      setShowPasswordErrorMsg(false);
    }
  }, [show]);

  const confirmHandler = async () => {
    if (submitting || password.length < 8) return;
    if (!passwordRegex.test(password)) return setShowPasswordErrorMsg(true);
    setSubmitting(true);
    onHide(password);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={() => onHide(false)}
      className="connect-modal"
      backdrop="static"
    >
      <Modal.Header className="modal-header">
        <h4>Confirm Action</h4>
        <CloseButton
          className="modal-close-button"
          onClick={() => onHide(false)}
        />
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="modal-title mb-4">
          Please confirm this action by entering your{' '}
          <b className="text-blue">authorization password</b> below.
        </div>
        <Form className="modal-form">
          <Form.Group className="mb-4 position-relative">
            <Form.Control
              id="transaction-password"
              autoFocus
              placeholder="Enter your authorization password"
              className="modal-form-input"
              type={viewPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setShowPasswordErrorMsg(false);
              }}
              onBlur={() => {
                if (password)
                  setShowPasswordErrorMsg(
                    password.length >= 8 && !passwordRegex.test(password)
                  );
              }}
              style={{ borderColor: showPasswordErrorMsg ? '#ff3f56' : '' }}
              onKeyDown={(event = {}) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  confirmHandler();
                }
              }}
            />
            {showPasswordErrorMsg && (
              <Form.Text className="text-pink">
                Please enter{password.length ? ' valid' : ''} authorization
                password
              </Form.Text>
            )}
            <div
              className="modal-view-password"
              onMouseDown={() => setViewPassword(true)}
              onMouseUp={() => setViewPassword(false)}
              onMouseLeave={() => setViewPassword(false)}
              title="View Password"
            >
              <EyeIcon />
            </div>
          </Form.Group>
          <Button
            disabled={submitting || password.length < 8}
            className="modal-submit-button mt-2"
            onClick={confirmHandler}
          >
            Confirm
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default TransactionVerificationModal;
