import actions from '../actions';
import { TOTAL_ROYALTY_SHARES } from '../../config';

export const initialState = {
  snftId: '',
  title: '',
  description: '',
  imagePath: '',
  promotionType: '',
  file: null,
  invalidTitle: false,
  //promotionCriteria
  fanStatus: '',
  accessLevelOptions: [],
  accessLevel: '',
  recepient: 0,
  startDate: null,
  endDate: null,
  scoreCalculation: '',
  //sponsorDetail
  sponsoredBy: '',
  sponsorAmount: 0,
  //sharedSalesDetail
  starShare: TOTAL_ROYALTY_SHARES,
  fanShare: 0,
  //accessNft reward
  accessNft: []
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('promotion_reward', serializedState);
  } catch {
    // ignore write errors
  }
};
export function Promotion(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PROMOTION_STATE: {
      saveState({ ...state, ...action.payload });
      return { ...state, ...action.payload };
    }
    case actions.RESET_PROMOTION_STATE: {
      saveState({ ...initialState });
      return { ...initialState };
    }
    default:
      return state;
  }
}
