import actions from '../actions';

export const initialState = {
  options: [
    { value: 'standard', label: 'Standard' },
    { value: 'limited', label: 'Limited' }
  ],
  files: [],
  originalFiles: null,
  isDescription: false,
  isReview: false,
  title: '',
  collectionType: '',
  ExpirationDate: null,
  description: '',
  collectionId: null,
  showLoader: false,
  duplicateTitle: false,
  isEdit: false,
  originalTitle: '',
  invalidTitle: false
};

const saveState = (state) => {
  try {
    if (state.files) state.files = [];
    const serializedState = JSON.stringify(state);
    localStorage.setItem('collection', serializedState);
  } catch {
    // ignore write errors
  }
};
export function Collection(state = initialState, action) {
  switch (action.type) {
    case actions.SET_COLLECTION_STATE: {
      saveState({ ...state, ...action.payload });
      return { ...state, ...action.payload };
    }
    case actions.RESET_COLLECTION_STATE: {
      localStorage.setItem('collection-image', null);
      saveState({ ...initialState });
      return { ...initialState };
    }
    default:
      return state;
  }
}
