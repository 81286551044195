import actions from '../actions';

export const initialState = {
  starConnectId: '',
  currentEdition: true,
  editionNo: 1,
  title: '',
  description: '',
  file: null,
  imagePath: '',
  videoFile: null,
  nftBackgroundStyle: 'red',
  activeStep: 0,
  starConnectPageBuilderId: '',
  useNftImage: true,
  useVideo: false,
  videoLink: '',
  heroSectionContent: null, // as description
  overviewSectionContent: null, // as overview
  starConnectSuccessPageId: '',
  isDefaultPage: true,
  isCustomPage: false,
  topHeading: '',
  successPageVideoLink: '', // as videoLink
  subHeading: '',
  successPageContent: null, // as description
  callToActionButtonName: '',
  callToActionButtonUrl: '',
  tokenGateContent: '',
  isEdit: false,
  invalidTitle: false,
  duplicateTitle: false
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('starconnectNFT', serializedState);
  } catch {
    // ignore write errors
  }
};

export function StarConnectNFT(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STARCONNECT_NFT: {
      saveState({ ...state, ...action.payload });
      return { ...state, ...action.payload };
    }
    case actions.RESET_STARCONNECT_NFT: {
      localStorage.setItem('starconnect_nft', null);
      saveState({ ...initialState, ...action.payload });
      return { ...initialState, ...action.payload };
    }
    default:
      return state;
  }
}
