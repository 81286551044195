/**
 * Order the following file in alphabet order so we can find the message key easily
 */
export const MSG = {
  // A
  AVAIL_BALANCE_ACCORDING:
    'Your available balance is {balance}. Please enter amount accordingly',
  ADDED_TO_CART: 'Item added to cart',
  ABOUT_BIO_UPDATED: 'About Me Bio updated successfully',
  ACCESS_TOKEN_CREATED: ' Access NFTs created',
  ACCESS_TOKEN_UPDATED: ' Access NFTs updated',
  ALREADY_REQUESTED: 'Already requested! Please wait for approval',
  ALREADY_CLAIMED: 'Already Claimed!',
  // B
  BUCKS_CREATED_SUCCESSFULLY: 'Bucks Created Successfully',
  BUCKS_EDITED_SUCCESSFULLY: 'Bucks Updated Successfully',
  BUNDLE_CREATED_SUCCESSFULLY: 'Bundle Created Successfully',
  BUNDLE_EDITED_SUCCESSFULLY: 'Bundle Updated Successfully',
  BUCKS_CREATION_WARNING_MSG:
    'Market Price should be greater than or equal to cash value',
  // C
  CONNECT_WALLET_FIRST: 'Please connect to StarStake',
  CONNECT_METAMASK: 'Plase connect metamask.',
  COLLECTION_CREATED_SUCCESSFULLY: 'Collection Created Successfully',
  COLLECTION_EDITED_SUCCESSFULLY: 'Collection Updated Successfully',
  CLICK_ON_IMAGE_TO_SELECT_IMAGE:
    'Click On Image To Select New Profile Picture',
  COPY_TO_CLIPBOARD: 'Copied to clipboard',
  CLICK_FOR_CREATOR_ACCESS: 'Please request creatorhub access.',
  COMING_SOON: 'Coming Soon !',
  CANCELLED_SIGN_IN: 'Sign in verfication cancelled!',
  // D
  DATA_NOT_AVAILABLE: 'Data not available',

  // E
  ENTER_VALID_AMOUNT: 'Plase enter valid amount',
  EMAIL_UPDATED: 'Email updated successfully',

  // F
  FILE_EXTENTION_NOT_SUPPORTED: 'File extension not supported!',
  FILL_ALL_FIELDS: 'Please fill all the fields !',

  // G

  // H

  // I
  INSUFFICIENT_CREDIT: 'You do not have sufficient credit',
  INSUFFICIENT_BALANCE: 'You do not have sufficient balance.',
  IMAGE_UPLOADED_SUCCESSFULLY: 'Image Uploaded Successfully !',
  IMAGE_NOT_UPLOADED: 'Image could not be uploaded !',
  INFT_DATA_CREATED: 'Brand Club data created successfully !',
  INFT_DATA_UPDATED: 'Brand Club data updated successfully !',
  INFT_IMAGE_UPLOADED: 'Brand Club image uploaded successfully !',
  INFT_PAGE_BUILDER_SETUP: 'Brand Club builder setup successfully !',
  INFT_PAGE_BUILDER_SAVED: 'Brand Club builder data updated successfully !',
  INFT_PAGE_BUILDER_NOT_UPDATED: 'Brand Club builder could not be updated !',
  INFT_ACCESS_REWARD_EMPTY: 'Please add atleast one access level !',
  INFT_ACCESS_REWARD_CREATED: 'Access level created successfully !',
  INFT_ACCESS_REWARD_UPDATED: 'Access level updated successfully !',
  INFT_ACCESS_REWARD_UPLOADED: 'Access level image uploaded successfully !',
  INFT_ACCESS_REWARD_NOT_SAVED: 'Please save the acccess level first !',
  INFT_ACCESS_REWARD_NOT_UPDATED: 'Access level could not be updated !',
  INFT_ACCESS_REWARD_REMOVED: 'Access level removed successfully !',
  INFT_ACCESS_REWARD_NOT_REMOVED: 'Access level could not be removed !',
  INVALID: 'Unauthorized Access !',
  // J

  // K

  // L

  // M
  MULTI_FILE_UPLOAD_NOT_POSSIBLE: 'Multiple file upload not possible.',
  MANAGER_DELETED: 'Manager de-activated successfully',
  MANAGER_ACTIVE: 'Manager activated successfully',
  // N
  NFTBUCKS_PURCHASED_SUCCESS:
    'Congrats! NFTbucks have been purchased successfully!',
  NFTBUCKS_REDEEMED_SUCCESS:
    'Congrats! Your bucks have been successfully redeemed',
  NFTBUCKS_TRANSFERED_SUCCESS: 'Congrats! NFTbucks transfered successfully!',
  NICKNAME_UPDATED: 'Nickname updated successfully.',
  NOTIFICATION_PREFERENCE_UPDATED:
    'Notification Prefrence Updated Successfully!',
  NO_CREATOR_PAGE_ACCESS: 'Not Authorizsed To Access Creator Hub.',
  // O

  // P
  PROFILE_PIC_UPLOADED: 'Profile picture uploaded successfully.',
  PLEASE_FILL_ALL_REQUIRED_FIELDS: 'Please fill all required fields.',
  PLEASE_FILL_ALL_REQUIRED_CLAIM_FIELDS:
    'Please fill all required claim fields.',
  PLEASE_FILL_VALID_WALLET_ADDRESS: 'Please type valid wallet address.',
  PLEASE_SELECT_WALLET: 'Please connect your wallet.',
  PLEASE_SIGN_IN: 'Please sign in to continue.',
  // Q

  // R
  REGISTER: 'Please Register to StarStake',
  RECONNECT_WALLET: 'Please try reconnecting your wallet or refresh the page.',
  REQUEST_SENT: 'Request Sent Successfully',

  // S
  SOMETHING_WRONG: `Looks like our digital playground is taking a breather. Hang tight, we're tuning up the fun for you!`,
  SUPPORTED_IMG_FILE: 'Please upload JPEG or JPG or PNG file.',
  SOCIAL_LINKS_UPDATED: 'Email and Social Media links updated successfully',
  SNFT_PURCHASE_SUCCESS: 'Brand Club purchased successfully !',

  // T
  TRANSACTION_IN_PROGRESS: 'One transaction already in progress.',

  // U
  UNABLE_ADD_CART: 'Unable to add item in cart.',
  UNABLE_TO_SIGN: 'Unable to sign. Please try again!',
  UNABLE_TO_UPDATE_EMAIL: 'Unable to update Email.',
  UNABLE_TO_UPDATE_NICKNAME: 'Unable to update NickName.',
  UNABLE_TO_UPLOAD_PROFILE_PIC: 'Unable to upload Profile picture.',
  UNABLE_TO_UPDATE_NOTIFICATION_PREFERENCE:
    'Unable to update notification preference.',

  // V

  // W
  WRONG_NETWORK: 'Wrong network choosen.'

  // X

  // Y

  // Z
};
