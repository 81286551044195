const actions = {
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  UPDATE_ACCESS_TOKEN: 'UPDATE_ACCESS_TOKEN',
  RESET_ACCESS_TOKEN: 'RESET_ACCESS_TOKEN',
  UPDATE_INVITATION_FORM: 'UPDATE_INVITATION_FORM',
  RESET_INVITATION_FORM: 'RESET_INVITATION_FORM',
  SET_BUCKS_STATE: 'SET_BUCKS_STATE',
  RESET_BUCKS_STATE: 'RESET_BUCKS_STATE',
  SET_COLLECTION_STATE: 'SET_COLLECTION_STATE',
  RESET_COLLECTION_STATE: 'RESET_COLLECTION_STATE',
  SET_BUNDLE_STATE: 'SET_BUNDLE_STATE',
  RESET_BUNDLE_STATE: 'RESET_BUNDLE_STATE',
  SET_SNFT_STATE: 'SET_SNFT_STATE',
  UPDATE_SNFT_STATE: 'UPDATE_SNFT_STATE',
  RESET_SNFT_STATE: 'RESET_SNFT_STATE',
  RESET_STARCONNECT_NFT: 'RESET_STARCONNECT_NFT',
  SET_STARCONNECT_NFT: 'SET_STARCONNECT_NFT',
  SET_PROMOTION_STATE: 'SET_PROMOTION_STATE',
  RESET_PROMOTION_STATE: 'RESET_PROMOTION_STATE'
};

export default actions;
