import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as SuccessCheckIcon } from '../../img/svg/CheckMark/CircleCheck3.svg';
import { ReactComponent as LoaderIcon } from '../../img/svg/Common/Loader.svg';
import FailureCrossIcon from '../../img/failure-cross.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VerifyEmail(props) {
  const query = useQuery();
  const userObj = {
    walletId: query.get('walletId'),
    email: decodeURIComponent(query.get('email')),
    token: query.get('token')
  };

  const [isVerified, setVerification] = useState(false);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [isLoading, setLoader] = useState(true);

  async function VerifyEmailApi() {
    try {
      const response = await props.prop.apiUtility.verifyEmail(userObj);
      setLoader(false);
      // console.log('success',response);
      if (response.status == 200) {
        const isVerified = response.data.emailVerified;
        setVerification(isVerified);
      } else if (response.message.includes('EMAIL_VERIFIED')) {
        setAlreadyVerified(true);
      }
    } catch (error) {
      setLoader(false);
      // console.log('error',error);
      setVerification(false);
    }
  }

  useEffect(() => {
    VerifyEmailApi();
  }, []);

  return (
    <div className="main">
      <div className="verify-email">
        {isLoading ? (
          <div className="text-center">
            <p>Verification in progress...</p>
            <LoaderIcon />
          </div>
        ) : (
          <div>
            <div className="d-grid gap-4 p-4">
              <div className="d-flex justify-content-center align-items-center ">
                {isVerified || alreadyVerified ? (
                  <SuccessCheckIcon />
                ) : (
                  <img alt="" src={FailureCrossIcon} />
                )}
              </div>
            </div>
            <div className="px-4 my-4 text-center">
              <p className="err-msg">
                {alreadyVerified
                  ? 'Email already verified'
                  : isVerified
                  ? 'Email verified successfully!'
                  : 'Email verification failed!'}
              </p>
              <Link className="btn btn-sm btn-primary mt-2" to="/">
                Return to Home
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail;
