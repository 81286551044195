import React from 'react';
import { useParams } from 'react-router-dom';

import './index.scss';
import { errorMsg } from '../../config';

import { ReactComponent as SuccessCheckIcon } from '../../img/svg/CheckMark/CircleCheck3.svg';

function CreditCardPayment({ walletId = '', sentTransaction = () => {} }) {
  const status = window.location.href.split('status=')[1] || '';
  const [loading, setLoading] = React.useState(false);
  const { orderId } = useParams();

  React.useEffect(() => {
    if (walletId && orderId) {
      setLoading(true);
      const data = JSON.stringify({ orderId, status });
      localStorage.setItem('credit-card-payment-status', data);
      if (status === 'failure') {
        errorMsg('Credit card payment failed, Please try again!');
      } else sentTransaction(orderId);
      setLoading(false);
    }
  }, [status, walletId]);

  if (loading) return null;

  return (
    <div className="credit-card-payment-status-wrapper">
      <div>
        <h4>
          Credit Card Payment Status :{' '}
          <span
            className={
              status === 'failure'
                ? 'text-pink'
                : status === 'success'
                ? 'text-green'
                : 'blue-grey-text'
            }
          >
            {status.toUpperCase()}
          </span>
        </h4>
        <p className={status === 'failure' ? 'text-pink' : 'text-blue'}>
          Your credit card payment was {status === 'success' ? '' : 'not'}{' '}
          successful!
        </p>
        {status === 'success' ? (
          <SuccessCheckIcon />
        ) : (
          <img src={require('../../img/failure-cross.png')} alt="" />
        )}
        <h6>
          Go back to{' '}
          <strong onClick={() => window.open('', '_self').close()}>
            previous tab
          </strong>{' '}
          and {status === 'failure' ? 'try again' : 'proceed further'}.
        </h6>
        <em>
          *Important: This transaction will show up on your statement as “CPAY
          IN*PAGADI”.
        </em>
      </div>
    </div>
  );
}

export default CreditCardPayment;
