import React from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import '../index.scss';
import utils from '../../../../utils';
import { APIUtiltity } from '../../../../API';
import { ReactComponent as CloseButton } from '../../../../img/svg/CrossMark/CircleCrossGrey.svg';

function ClaimCertificateAccessNFT({ onHide = () => {} }) {
  const codeInputRefs = React.useRef([]);
  const [submitting, setSubmitting] = useState(false);
  const [codeArray, setCodeArray] = useState(['', '', '', '', '', '']);
  const [errorMsg, setErrorMsg] = useState('');
  const code = codeArray.join('');
  const history = useHistory();

  const codeInputHandler = (key = '', index = 0, clear = false) => {
    if (submitting) return;
    let value = '';
    const start = codeArray.slice(0, index);
    const end = index === 6 ? [] : codeArray.slice(index + 1);
    if (clear) {
      if (key === 'Enter') return claimHandler();
      if (key !== 'Delete' && key !== 'Backspace') return;
    } else if (key.length !== 1 || !key.trim()) return;
    else value = key;
    setErrorMsg('');
    const inputFocusIndex = value ? index + 1 : index - 1;
    if (codeInputRefs.current[inputFocusIndex]) {
      codeInputRefs.current[inputFocusIndex].focus();
      codeInputRefs.current[inputFocusIndex].select();
    }
    setCodeArray([...start, value, ...end]);
  };

  const codePasteHandler = (ev = {}) => {
    setErrorMsg('');
    ev.preventDefault();
    const clip = ev.clipboardData.getData('text');
    const code = clip.replace(/\s/g, '');
    if (!/^[0-9A-Za-z]{6}$/.test(code)) return errorMsg('Invalid code pasted!');
    setCodeArray([...code]);
    codeInputRefs.current[5].focus();
  };

  const claimHandler = async () => {
    setSubmitting(true);
    try {
      const res = await APIUtiltity.claimCertificateAccessNFT(code);
      if (res.status === 200) {
        const obj = { ...res.data, fromPath: '/StakeHub' };
        const cipherText = utils.getEncrypted(obj);
        history.push('/AccessNFTCheckout/' + cipherText);
        onHide(true);
      } else if (res.status === 409) setErrorMsg('Already claimed!');
      else if (res.message && res.message.includes('invalid')) {
        setErrorMsg('Invalid redemption code!');
      } else setErrorMsg(res.message);
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <Modal
      show
      centered
      onHide={() => onHide(false)}
      className="claim-certificate-modal"
    >
      <Modal.Header className="modal-header">
        <h4>Redeem Certificate NFT</h4>
        <CloseButton
          className="modal-close-button"
          onClick={() => onHide(false)}
        />
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="modal-title mb-4">
          Enter the one-time redemption code <br />
          provided to you to receive your Access NFT <br />
          certificate.
        </div>
        <div className="otp-input-container mb-3">
          {codeArray.map((code = '', index = 0) => (
            <input
              key={index}
              type="text"
              name={'otp' + (index + 1)}
              maxLength="1"
              autoComplete="off"
              className="otp-input"
              tabIndex={index + 1}
              value={code}
              onKeyUp={({ key = '' }) => codeInputHandler(key, index, true)}
              onChange={({ target: { value = '' } }) =>
                codeInputHandler(value.slice(-1), index)
              }
              onPaste={codePasteHandler}
              ref={(ref) => (codeInputRefs.current[index] = ref)}
            />
          ))}
        </div>
        <h6 className="mb-2 text-pink">{errorMsg}&nbsp;</h6>
        <div className="modal-btn-container py-2">
          <Button
            disabled={submitting || code.length !== 6}
            className="modal-submit-button"
            onClick={claimHandler}
          >
            Redeem Now!
          </Button>
          <Button variant="link" className="link-btn">
            How it works
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ClaimCertificateAccessNFT;
