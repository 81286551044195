import { legacy_createStore as createStore } from 'redux';

import rootReducer from './reducers';
import { initialState as BuckState } from './reducers/BucksCreation';
import { initialState as CollectionState } from './reducers/CollectionCreation';
import { initialState as BundleState } from './reducers/BundleCreation';
import { initialState as AccessNFTState } from './reducers/AccessNFTCreation';
import { initialState as SnftState } from './reducers/SnftMinting';
import { initialState as StarConnectNFTState } from './reducers/StarConnectNFTCreation';
import { initialState as PromotionalRewardState } from './reducers/PromotionCreation';

const loadState = () => {
  try {
    let state = {};
    //bucks
    const bucksState = localStorage.getItem('bucks');
    if (bucksState) state.Bucks = { ...BuckState, ...JSON.parse(bucksState) };

    //bundle
    const bundleState = localStorage.getItem('bundle');
    if (bundleState)
      state.Bundle = { ...BundleState, ...JSON.parse(bundleState) };

    //collection
    const collectionState = localStorage.getItem('collection');
    if (collectionState)
      state.Collection = { ...CollectionState, ...JSON.parse(collectionState) };

    //access NFT
    const accessNFTState = localStorage.getItem('accessNFT');
    if (accessNFTState)
      state.AccessNFT = {
        ...AccessNFTState,
        ...JSON.parse(accessNFTState)
      };

    //sNFT
    const sNFTState = localStorage.getItem('sNFT');
    if (sNFTState) state.SNFT = { ...SnftState, ...JSON.parse(sNFTState) };

    //StarConnect NFT
    const starConnectNFTState = localStorage.getItem('starconnectNFT');
    if (starConnectNFTState)
      state.StarConnectNFT = {
        ...StarConnectNFTState,
        ...JSON.parse(starConnectNFTState)
      };

    //Promotional Reward
    const promotionalRewardState = localStorage.getItem('promotion_reward');
    if (promotionalRewardState)
      state.Promotion = {
        ...PromotionalRewardState,
        ...JSON.parse(promotionalRewardState)
      };

    return state;
  } catch (err) {
    return undefined;
  }
};

const initialState = loadState();
const store = createStore(rootReducer, initialState);

export default store;
