import actions from '../actions';

export const initialState = {
  step: '',
  supply: '',
  imagePath: '',
  mainImagePath: '',
  rarity: null,
  accessType: null,
  accessTokenId: null,
  summary: '',
  explainerLink: null,
  availability: null,
  additionalField: [],
  reuploadImage: false,
  file: null,
  preview: null,
  files: [],
  originalFiles: [],
  accessNFTTitle: '',
  invalidTitle: false,
  duplicateTitle: false,
  collectionsList: [],
  accessNftCollectionId: '',
  accessNFTCollection: '',
  snftList: [],
  snftId: '',
  accessLevelOptions: [],
  accessLevel: [],
  price: null,
  salePrice: null,
  ownershipScore: 250,
  externalLinkUrlEnabled: false,
  externalLinkUrl: '',
  certificateMessage: '',
  deliveryType: '',
  deliveryInstruction: '',
  pageEditorContent: '',
  isSummary: false,
  excludeFromMarketPlace: false,
  accessNftId: null,
  approved: false,
  isFinalStep: false,
  loading: false,
  inputField: []
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('accessNFT', serializedState);
  } catch {
    // ignore write errors
  }
};

export function AccessNFT(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ACCESS_TOKEN: {
      return { ...initialState, ...action.payload };
    }
    case actions.UPDATE_ACCESS_TOKEN: {
      saveState({ ...state, ...action.payload });
      return { ...state, ...action.payload };
    }
    case actions.RESET_ACCESS_TOKEN: {
      saveState({ ...initialState });
      return { ...initialState };
    }
    default:
      return state;
  }
}
