import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { ReactComponent as ProcessingIcon } from '../../../img/svg/Circle/HalfCircleBlue.svg';
import { ReactComponent as SuccessCheckIcon } from '../../../img/svg/CheckMark/CircleCheck3.svg';

class LoaderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: props.isLoader,
            show: props.show,
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        this.setStateFromWithNewProps(newProps)
    }

    async setStateFromWithNewProps(props) {
        if (props) {
            await this.setState({
                isLoader: props.isLoader,
                show: props.show,
            })
        }
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div >
                <Modal
                    className="hub-modal confirm"
                    show={true}
                    centered>

                    <Modal.Body  >
                        <div className="confirm-modal-body" style={{}}>
                            {!this.state.isLoader
                                ?
                                <div className="processing-icon rotate mb-3">
                                    <ProcessingIcon />
                                </div>
                                :
                                <div className="processing-icon   mb-3">
                                    <SuccessCheckIcon />
                                </div>}

                            <div className="text text-center">
                                {!this.state.isLoader
                                    ?
                                    <p>Hold on, confirming your request.</p>
                                    :
                                    <p>This access NFTs has been claimed.</p>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    };
}

export default LoaderModal;