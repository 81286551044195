import { combineReducers } from 'redux';

import CreatorHubInvitation from './CreatorHubInvitationReducer';
import { AccessNFT } from './AccessNFTCreation';
import { Bucks } from './BucksCreation';
import { Collection } from './CollectionCreation';
import { Bundle } from './BundleCreation';
import { SNFT } from './SnftMinting';
import { StarConnectNFT } from './StarConnectNFTCreation';
import { Promotion } from './PromotionCreation';

export default combineReducers({
  CreatorHubInvitation,
  AccessNFT,
  Bucks,
  Collection,
  Bundle,
  SNFT,
  StarConnectNFT,
  Promotion
});
