import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { APIUtiltity } from '../../API';
import { errorMsg, successMsg } from '../../config';
import utils from '../../utils';

import { ReactComponent as EyeIcon } from '../../img/svg/Others/password-eye.svg';

const nickNameRegex = /^[a-zA-Z0-9_]*$/;
const defaultNickNameErrorMsg =
  '*Only alphabets, numbers, underscore and one dot allowed';

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;
const defaultPasswordErrorMsg =
  '*Password must be minimum 8 characters and should contain at least 1 lowercase, 1 uppercase letter, 1 special character and 1 number';

function AccountSetup({ setStep = () => {}, setUserData = () => {} }) {
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [nickName, setNickName] = useState('');
  const [confirmNickName, setConfirmNickName] = useState('');
  const [nickNameErrorMsg, setNickNameErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [section, setSection] = useState('nickname'); // nickname -> password

  const accountCreationHandler = async () => {
    if (section === 'nickname') {
      if (!nickNameRegex.test(nickName.replace('.', ''))) {
        return setNickNameErrorMsg(defaultNickNameErrorMsg);
      } else if (nickName !== confirmNickName) {
        return setShowError(true);
      }
      setShowError(false);
      setSubmitting(true);
      const res = await APIUtiltity.checkUniqueNickName(nickName);
      if (res?.status === 200) setSection('password');
      else {
        errorMsg('Nickname already taken');
        setNickNameErrorMsg('Nickname already taken');
      }
      setSubmitting(false);
    } else if (section === 'password') {
      if (!passwordRegex.test(password)) {
        return setPasswordErrorMsg(defaultPasswordErrorMsg);
      } else if (password !== confirmPassword) {
        return setShowError(true);
      }
      if (submitting) return;
      setShowError(false);
      setSubmitting(true);
      const data = {
        nickName: nickName,
        txnPwd: utils.getEncryptedPassword(password)
      };
      const res = await APIUtiltity.setupNewUser(data);
      if (res.status === 200) {
        setUserData(res.data);
        successMsg('Account created successfully');
        // onHide(true);
        setStep(2);
      } else {
        setSection('nickname');
        errorMsg(res.message);
      }
      setSubmitting(false);
    } else setStep(2);
  };

  return (
    <div className="account-setup-modal">
      {section === 'nickname' ? (
        <div className="modal-title mb-4">
          Great! Please choose your <b className="text-blue"> StarStake ID</b>.
          <p style={{ fontSize: 14, lineHeight: '18px' }} className="mt-2">
            *Your StarStake ID is how others view you and cannot be changed in
            the future. We recommend choosing your name or brand.
          </p>
        </div>
      ) : section === 'password' ? (
        <div className="modal-title mb-4">
          Good choice <span className="blue">{nickName} </span>! Next you’ll
          need to create an
          <span className="pink"> Authorization Password</span>
          <p style={{ fontSize: 14, lineHeight: '18px' }} className="mt-2">
            *Make sure to record and store your password somewhere safe! Your
            authorization password is an extra layer of security used anytime
            you interact with your wallet on StarStake. It’s kinda like 2FA but
            better!
          </p>
        </div>
      ) : null}
      <Form className="modal-form mb-4" autoComplete="off">
        {section === 'nickname' ? (
          <NickName
            accountCreationHandler={accountCreationHandler}
            showError={showError}
            setShowError={setShowError}
            nickName={nickName}
            setNickName={setNickName}
            nickNameErrorMsg={nickNameErrorMsg}
            confirmNickName={confirmNickName}
            setConfirmNickName={setConfirmNickName}
            setNickNameErrorMsg={setNickNameErrorMsg}
            submitting={submitting}
          />
        ) : section === 'password' ? (
          <AuthPassword
            accountCreationHandler={accountCreationHandler}
            showError={showError}
            setShowError={setShowError}
            password={password}
            setPassword={setPassword}
            passwordErrorMsg={passwordErrorMsg}
            setPasswordErrorMsg={setPasswordErrorMsg}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            submitting={submitting}
          />
        ) : null}
        <div>
          <Button
            disabled={submitting || showError}
            className="modal-submit-button mt-2"
            onClick={accountCreationHandler}
          >
            Continue
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AccountSetup;

const NickName = ({
  submitting = false,
  showError = false,
  setShowError = () => {},
  nickName = '',
  setNickName = () => {},
  confirmNickName = '',
  setConfirmNickName = () => {},
  nickNameErrorMsg = '',
  setNickNameErrorMsg = () => {},
  accountCreationHandler = () => {}
}) => {
  return (
    <>
      <Form.Group className="mb-4">
        <Form.Control
          id="connect-nickname"
          autoFocus
          autoComplete="off"
          placeholder="Enter your permanent username"
          className="modal-form-input"
          type="text"
          value={nickName}
          onChange={(e) => {
            if (submitting) return;
            setNickName(e.target.value);
            setNickNameErrorMsg(
              !nickNameRegex.test(e.target.value.replace('.', ''))
                ? defaultNickNameErrorMsg
                : ''
            );
          }}
          style={{ borderColor: nickNameErrorMsg !== '' ? '#ff3f56' : '' }}
        />
        <Form.Text className="text-pink">{nickNameErrorMsg}</Form.Text>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Control
          id="connect-nickname-confirm"
          autoComplete="off"
          placeholder="Confirm your permanent username"
          className="modal-form-input"
          type="text"
          value={confirmNickName}
          onChange={(e) => {
            if (submitting) return;
            setConfirmNickName(e.target.value);
            if (showError) setShowError(nickName !== confirmNickName);
          }}
          style={{
            borderColor:
              confirmNickName && nickName !== confirmNickName ? '#ff3f56' : ''
          }}
          onBlur={() => setShowError(nickName !== confirmNickName)}
          onKeyDown={(event = {}) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              accountCreationHandler();
            }
          }}
        />
        {showError && nickName !== confirmNickName && (
          <Form.Text className="text-pink">StarStake ID should match</Form.Text>
        )}
      </Form.Group>
    </>
  );
};

const AuthPassword = ({
  submitting = false,
  showError = false,
  setShowError = () => {},
  password = '',
  setPassword = () => {},
  passwordErrorMsg = '',
  setPasswordErrorMsg = () => {},
  confirmPassword = '',
  setConfirmPassword = () => {},
  accountCreationHandler = () => {}
}) => {
  const [viewPassword, setViewPassword] = useState(0);

  return (
    <>
      <Form.Group className="mb-4 position-relative">
        <Form.Control
          id="connect-password"
          autoFocus
          placeholder="Create your authorization password"
          className="modal-form-input"
          type={viewPassword === 1 ? 'text' : 'password'}
          value={password}
          onChange={(e) => {
            if (submitting) return;
            setPassword(e.target.value);
            setPasswordErrorMsg('');
          }}
          onBlur={() =>
            setPasswordErrorMsg(
              passwordRegex.test(password) ? '' : defaultPasswordErrorMsg
            )
          }
          style={{
            borderColor: passwordErrorMsg !== '' ? '#ff3f56' : '',
            paddingRight: 40
          }}
        />
        <Form.Text className="text-pink">{passwordErrorMsg}</Form.Text>
        <div
          className="modal-view-password"
          onMouseDown={() => setViewPassword(1)}
          onMouseUp={() => setViewPassword(0)}
          onMouseLeave={() => setViewPassword(0)}
          title="View Password"
        >
          <EyeIcon />
        </div>
      </Form.Group>
      <Form.Group className="mb-4 position-relative">
        <Form.Control
          id="connect-confirm"
          placeholder="Confirm your authorization password"
          className="modal-form-input"
          type={viewPassword === 2 ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => {
            if (submitting) return;
            setConfirmPassword(e.target.value);
            if (showError) setShowError(password !== confirmPassword);
          }}
          style={{
            borderColor:
              confirmPassword && password !== confirmPassword ? '#ff3f56' : '',
            paddingRight: 40
          }}
          onBlur={() => setShowError(password !== confirmPassword)}
          onKeyDown={(event = {}) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              accountCreationHandler();
            }
          }}
        />
        {showError && password !== confirmPassword && (
          <Form.Text className="text-pink">Password should match</Form.Text>
        )}
        <div
          className="modal-view-password"
          onMouseDown={() => setViewPassword(2)}
          onMouseUp={() => setViewPassword(0)}
          onMouseLeave={() => setViewPassword(0)}
          title="View Password"
        >
          <EyeIcon />
        </div>
      </Form.Group>
    </>
  );
};
