import actions from '../actions';

const defaultAccessLevel = {
  isActive: false,
  accessLevelId: '',
  levelTitle: '',
  file: null,
  accessLevelImagePath: '',
  levelDescription: '',
  supplyType: '',
  supplyData: '',
  accessLevelStake: 0,
  accessLevelPrice: '',
  purchasePrice: '',
  ownershipScore: '',
  accessNftArray: [],
  saved: false,
  invalidTitle: false
};

export const initialState = {
  snftId: '',
  nickName: '',
  profilePicture: '',
  mintingStatus: '',
  sectionNumber: 0,
  lastPageAccess: 0,
  snftTitle: '',
  snftCategory: '',
  file: null,
  invalidTitle: false,
  snftImagePath: '',
  snftBrandLogo: null,
  descriptionView: false,
  snftOneLiner: '',
  aboutSnft: '',
  snftDropDate: null,
  snftExpireDate: null,
  thumbnailFile: null,
  thumbnailImagePath: '',
  snftPageBuilderId: '',
  layoutType: 0,
  bannerFile: null,
  headerSectionImagePath: '',
  videoLink: '',
  externalLinkUrl: '',
  externalLinkName: '',
  socialMedia: [],
  glance: '',
  overview: '',
  accessLevelArray: [],
  accessLevel1: defaultAccessLevel,
  accessLevel2: defaultAccessLevel,
  accessLevel3: defaultAccessLevel,
  accessLevel4: defaultAccessLevel,
  accessLevel5: defaultAccessLevel,
  selectedAccessLevel: {},
  edit: false
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('sNFT', serializedState);
  } catch {
    // ignore write errors
  }
};

export function SNFT(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SNFT_STATE: {
      saveState({ ...initialState, ...action.payload });
      return { ...initialState, ...action.payload };
    }
    case actions.UPDATE_SNFT_STATE: {
      saveState({ ...state, ...action.payload });
      return { ...state, ...action.payload };
    }
    case actions.RESET_SNFT_STATE: {
      saveState({ ...initialState });
      return { ...initialState };
    }
    default:
      return state;
  }
}
